import React from 'react';
import ReactPlayer from 'react-player';

const Wistia = () => {
  return (
    <ReactPlayer
      controls={true}
      url='https://home.wistia.com/medias/e4a27b971d'
    />
  );
};

export default Wistia;
