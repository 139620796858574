import React from 'react';
import ReactPlayer from 'react-player';

const Facebook = () => {
  return (
    <ReactPlayer
      controls={true}
      url='https://www.facebook.com/facebook/videos/10153231379946729/'
    />
  );
};

export default Facebook;
