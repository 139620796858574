const folderList = [
  {id: 120, name: 'All', alias: 'all'},
  {id: 121, name: 'Starred', alias: 'starred'},
  {id: 122, name: 'Priority', alias: 'priority'},
  {id: 123, name: 'Scheduled', alias: 'scheduled'},
  {id: 124, name: 'Today', alias: 'today'},
  {id: 125, name: 'Completed', alias: 'completed'},
  {id: 126, name: 'Deleted', alias: 'deleted'},
];
export default folderList;
